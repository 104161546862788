<template>
  <div>
    <div class="container-login">
      <v-app id="inspire">
        <v-main>
          <v-row no-gutters>
            <v-col class="hidden-xs-only bg-picture-wrapper" sm="6" md="6">
              <v-img :src="images.backgroundImage" height="100vh" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"></v-img>
            </v-col>
            <v-col cols="12" sm="6" md="6" style="max-height: 100vh">
              <v-container class="fill-height align-content-space-between pa-0" fluid>
                <v-row no-gutters class="">
                  <v-col>
                    <v-app-bar flat>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn text to="/">Home</v-btn>
                        <v-btn text to="/account/login">Login</v-btn>
                      </v-toolbar-items>
                    </v-app-bar>
                  </v-col>
                </v-row>

                <v-row no-gutters align="center" justify="center">
                  <v-col  cols="8" xl="4" md="6" sm="8">
                    <v-img :src="images.logoImage" class="mb-5 mx-auto form-logo"></v-img>
                  </v-col>
                </v-row>

                <!-- Content -->
                <router-view />
                <!-- End content -->

                <v-row no-gutters>
                  <v-col>
                    <v-footer padless>
                      <v-card
                          flat
                          tile
                          width="100%"
                          class="text-center"
                      >
                        <v-card-text>
                          <v-btn
                              class="mx-4"
                              icon
                          >
                            <v-icon size="24px">mdi-facebook</v-icon>
                          </v-btn>
                          <v-btn
                              class="mx-4"
                              icon
                          >
                            <v-icon size="24px">mdi-twitter</v-icon>
                          </v-btn>
                          <v-btn
                              class="mx-4"
                              icon
                          >
                            <v-icon size="24px">mdi-linkedin</v-icon>
                          </v-btn>
                        </v-card-text>

                        <v-card-text class="my-0 py-0">
                          <a href="#" class="grey--text">Privacy Policy</a> | <a href="#" class="grey--text">Terms & Conditions</a>
                        </v-card-text>

                        <v-card-text>
                          Copyright ©D2D {{ new Date().getFullYear() }}
                        </v-card-text>
                      </v-card>
                    </v-footer>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-main>
      </v-app>
    </div>
  </div>
</template>

<script>
import backgroundImage from "@/assets/background-images/img1-min.jpg";
import logoImage from '@/assets/logo2.jpg';

export default {
  name: 'AccountFormLayout',

  data() {
    return {
      images: {
        backgroundImage,
        logoImage
      },
    }
  }

}
</script>